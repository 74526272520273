@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&display=swap');
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.5.2/css/all.min.css');
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {

    body {
        @apply font-body;
    }


}