.bg-gradient-perfect{
    background: linear-gradient(180deg, #F7F7F7 0%, rgba(255, 255, 255, 0) 47.39%);
}
.drop-shadow-box{
    box-shadow: 0px 4px 12px 0px #00000014;

}

.mySwiper {
    overflow: visible; /* Allow neighboring slides to overflow */
  }
  
  .mySwiper .swiper-slide {
    opacity: 1; /* Make non-active slides semi-transparent */
    transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
  }
  
  .mySwiper .swiper-slide-active {
    opacity: 1; /* Make the active slide fully opaque */
    transform: scale(1.1); /* Optional: Slightly scale the active slide */
  }
